@media not screen and (min-width: 1280px) {
  .eT016alH {
    display: none;
  }
}

.Bb_zb6OT {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: minmax(0, max-content);
  gap: 8px;
}
.Bb_zb6OT .vtXac2s4 {
  color: #8f92a1;
  font-size: 20px;
  line-height: 0;
}

